import { navigate } from "gatsby";
import React from "react";
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from "react-redux";
import AppBar from "../components/appBar/appBar";
// import Table from "../components/table/table"
import Card from "../components/card/card";
import FAQs from "../components/faqs/faqs";
import IFrame from "../components/iFrame/iFrame";
import LaunchForm from "../components/launchForm/launchForm";
import Layout from "../components/layout/layout";
import MainSection from "../components/mainSection/mainSection";
import Modal from "../components/modal/modal";
import OptOut from "../components/optOut/optOut";
import PageSection from "../components/pageSection/pageSection";
import SecondarySection from "../components/secondarySection/secondarySection";
import SEO from "../components/seo/seo";

import * as ACTIONS from "../state/actions";

const tcPdf = <IFrame file="../TC.pdf" title="Términos de uso" />;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad" />;
// const footerDesc = "";
const contentMaxWidth = "980px";

/*const dataTable = {
    columns: ['','EVVA'],
    rows: [
        {
            desc: "Cuenta empresarial digital",
            evva: true
        },
        {
            desc: "Altos redimientos",
            evva: true
        },
        {
            desc: "Tarjeta de pagos",
            evva: true
        },
        {
            desc: "Experiencia de apertura",
            evva: "Digital"
        },
        {
            desc: "Control de gastos",
            evva: true
        },
        {
            desc: "Servicio de atención",
            evva: "Personalizado"
        }
    ]
};*/

const dataFilters = [
    {
        id: "general",
        label: "General"
    },
    {
        id: "onboarding",
        label: "Requisitos de apertura"
    }
];

const dataFAQs = [
    {
        id: 0,
        title: "¿Por qué se llama EVVA?",
        description: "<p>EVVA proviene de nuestro manifiesto:<br /><strong>Emprendimiento:</strong> nuestra inspiración diaria. Nos enfocamos en las necesidades financieras de los emprendedores para que ellos se enfoquen en lo que realmente necesitan: su empresa.<p><p/><strong>Veracidad:</strong> ser transparente y franco con nuestros clientes. Desaparecemos las comisiones ocultas y tarifas complicadas.<p><p/><strong>Velocidad:</strong> Todo está cambiando constantemente. El ser ágil y adaptable es la clave para tener éxito. Creamos experiencias financieras ágiles con el objetivo final de hacer que las pequeñas empresas crezcan al siguiente nivel.<p><p/><strong>Accesibilidad:</strong> siendo inclusivos. Nuestros servicios están abiertos a todos los empresarios que quieran crear un impacto y contribuir a la economía del país.</p>",
        categories: ["general"]
    },
    {
        id: 1,
        title: "¿Para quién es EVVA?",
        description: "<p>Actualmente estamos abiertos a cualquier residente mexicano con identificación INE y que sea director de una compañía estructurada como una sociedad  SAS, SAPI, SA de CV o SC o simplemente freelancer es decir personas físicas con actividad empresarial.</p>",
        categories: ["general"]
    },
    {
        id: 2,
        title: "¿Qué es EVVA?",
        description: "<p>EVVA es una plataforma digital que te conecta a diversos servicios financieros relevantes para tu empresa o negocio con la finalidad de simplificar tu vida financiera. Todos los servicios financieros ofrecidos en la plataforma son proveídos por una o más instituciones financieras, las cuales están reguladas y supervisadas por la CNBV, BANXICO y CONDUSEF.</p>",
        categories: ["general"]
    },
    {
        id: 3,
        title: "¿Qué es exactamente la cuenta empresarial que se ofrece?",
        description: "<p>Es una cuenta que te permite invertir tu dinero de tesorería cuando no lo estás utilizando, con la finalidad de ganar rendimientos diarios. Cuentas con liquidez inmediata por lo tanto puedes retirar tu dinero en cualquier momento, La cuenta es emitida por Kuspit Casa de Bolsa, los fondos son administrados por Scotia Global Asset Management, una compañía de ScotiaBank. Para obtener información detallada sobre este fondo, puedes leer la descripción detallada de este fondo en este <a href='https://scotiabankfiles.azureedge.net/scotia-bank-mexico/spanish/pdf/personas/fondos-de-inversion/prospectos/Prospecto_SCOTIAG.pdf?t=1592919143996' target='_blank' rel='noopener noreferrer'>documento</a></p>",
        categories: ["general"]
    },
    {
        title: "¿Existe algún riesgo en mi cuenta empresarial?",
        description: "<p>El horizonte de Inversión es corto plazo. El Fondo está expuesto principalmente a tasas de interés, con una duración de como máximo 1 año en el portafolio, además de mantener al menos un 30% del activo neto del Fondo en valores de fácil realización y/o valores con plazo de vencimiento menor a 3 meses, por lo que se considera que el riesgo de este Fondo es bajo. El principal riesgo asociado a este Fondo son las variaciones en las tasas de interés y el riesgo cambiario. Por lo cual el patrimonio se puede ver afectado en el corto plazo derivado de los movimientos en alzas de las tasa de interés y al tipo de cambio.</p>",
        categories: ["general"]
    },
    {
        title: "¿Qué otros servicios ofrece EVVA?",
        description: "<p>Con nuestros productos y aliados, estamos desarrollando un conjunto de servicios para tu negocio, desde transacciones seguras en caso de vender o comprar hasta una visualización fácil y clara de tu situación fiscal. Visita nuestra página de <a href='/marketplace' target='_self' rel='noopener noreferrer'>marketplace financiero para más información</a>.</p>",
        categories: ["general"]
    },
    {
        title: "¿Cuál es la mejor forma de contactar a EVVA?",
        description: "<p>Si eres cliente nos puedes contactar a través del chat de la aplicación web en los horario de 7:00 AM a 17:00 PM. De igual forma nos puedes mandar un correo a <a href='mailto:ayuda@evvafinanzas.com' target='_blank' rel='noopener noreferrer'>ayuda@evvafinanzas.com</a> en caso de tener alguna duda, idea o problema acerca del servicio.</p><p>Si sólo tienes curiosidad no dudes en contactarnos en <a href='mailto:info@evvafinanzas.com' target='_blank' rel='noopener noreferrer'>info@evvafinanzas.com</a> Nos encantará escuchar de ti y de responder preguntas.</p>",
        categories: ["general"]
    },
    {
        title: "¿Cuánto cuesta EVVA?",
        description: "<p>Tenemos planes flexibles que crecen contigo, para empresas recién establecidas tenemos un plan totalmente gratuito, para empresas con mayor tracción y facturación tenemos un plan mensual de $175 MXN.</p>",
        categories: ["general"]
    },
    {
        title: "¿Qué necesito para abrir mi cuenta empresarial?",
        description: "<p>Para poder adquirir tu cuenta empresarial recuerda que debes ser el apoderado legal de tu empresa. De igual forma se requieren los siguientes documentos para ser registrados en nuestra plataforma:</p><p>Empresas constituidas como persona moral:</p><ul><li>Clave CURP de apoderado legal</li><li>Foto de frente de la tarjeta de elector del apoderado legal, debe ser en formato JPG, PNG, la puedes tomar con tu celular o laptop, sólo asegura que sea totalmente clara</li><li>Foto de reverso de la tarjeta de elector  del apoderado legal, debe ser en formato JPG, PNG, la puedes tomar con tu celular o laptop, sólo asegura que sea totalmente clara</li><li>Acta constitutiva en formato pdf</li><li>Acta de cambio de poderes, esto aplica en caso que tu empresa haya sufrido algún cambio de apoderado legal</li><li>Comprobante de domicilio fiscal en pdf, reciente no mayor a 3 meses, puede ser un recibo de agua, luz o teléfono</li><li>Constancia fiscal de tu negocio, la cual la puedes generar en el portal SAT</li><li>FIEL (firma digital de tu negocio) ten a la mano tus archivos .KEY .CER y contraseña</li></ul><p>Si eres persona física con actividad empresarial:</p><ul><li>Clave CURP</li><li>Foto de frente de la tarjeta de elector del apoderado legal, debe ser en formato JPG, PNG, la puedes tomar con tu celular o laptop, sólo asegura que sea totalmente clara</li><li>Foto de reverso de la tarjeta de elector  del apoderado legal, debe ser en formato JPG, PNG, la puedes tomar con tu celular o laptop, sólo asegura que sea totalmente clara</li><li>Comprobante de domicilio fiscal en pdf, reciente no mayor a 3 meses, puede ser un recibo de agua, luz o teléfono</li></ul>",
        categories: ["onboarding"]
    },
    {
        title: "¿Cuáles son los datos requeridos de mi empresa?",
        description: "<p>Domicilio fiscal, teléfono y RFC de tu empresa</p>",
        categories: ["onboarding"]
    }
];

const FreelancersAccount = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const launchFormHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });

        const modal = {
            display: true,
            content: <LaunchForm lang={appState.lang} title="¡Gracias por tu interés! Estamos apunto de lanzar 🚀" subtitle="Solicita tu invitación y serás de los primeros en saber en cuanto estemos listos." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpCreated" />
        };

        dispatch(ACTIONS.showModal(modal))
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({ display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({ display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const goFinancingHandler = () => {
        navigate("/financiamiento/")
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    const categoryFAQsHandler = (id) => {
        const { categoryFAQs } = appState;
        const categoryId = categoryFAQs.find(item => item === id);

        if (categoryId) {
            const index = categoryFAQs.indexOf(categoryId);

            if (index > -1 && categoryFAQs.length > 1) {
                categoryFAQs.splice(index, 1);
            }

        } else {
            // categoryFAQs.push(id)
            categoryFAQs[0] = id
        }

        dispatch(ACTIONS.setCategoryFAQs(categoryFAQs));
    };

    return (
        <Layout lang={appState.lang} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} title="La cuenta empresarial digital que hace crecer tu dinero, 100% en línea" description="PyMEs y Startups, Freelancers dile adiós al papeleo y largas colas en sucursal. Con EVVA solicita tu cuenta empresarial digital que genera rendimientos diarios, 100% en línea." />
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal} />}
            {!cookies.consent &&
                <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler} />
            }
            <MainSection title="¡Hola trabajador independiente!" description="<h3>Como freelancer tu tiempo cuesta, dile adiós al papeleo y largas colas en sucursal. Solicita tu cuenta empresarial digital que genera rendimientos diarios, 100% en línea.</h3>" position="left" image="hero-home-v3.png" actionLabel="Solicita tu invitación" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUp">
                <AppBar pathname={appState.path} />
            </MainSection>
            <PageSection>
                <div style={{ marginTop: "-80px", zIndex: "1" }}>
                    <Card
                        subtitle="Obtén financiamiento para tu negocio"
                        description="<p>Hemos unido fuerzas con Uniclick by Unifin, líder en el mercado de financiamiento, que ha impulsado a miles de PyMEs en México.</p>"
                        descriptionColor="#363636"
                        image="search-icon.svg"
                        imageWidth="240px"
                        imageHeight="160px"
                        actionLabel="Saber mas"
                        action={goFinancingHandler}
                        actionVariant="outlined"
                        bgColor="#fff"
                        horizontal
                        padding
                        shadow
                    />
                </div>
            </PageSection>
            <PageSection title="Todo lo que esperas de una cuenta bancaria estándar, pero mucho mejor..." maxWidth={contentMaxWidth}>
                <Card
                    title="100% en línea"
                    description="<p>Haz la apertura desde tu celular o computadora y realiza cualquier operación. Sin papeleo ni vueltas al banco.</p>"
                    descriptionColor="#363636"
                    image="open-bank-account.png"
                    imageWidth="260px"
                    imageHeight="280px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
                <Card
                    title="Para empresas"
                    description="<p>No importa si eres Freelancer, Startup PyME, SAS, SAPI, SA de CV o SC. Abre tu cuenta empresarial en línea.</p>"
                    descriptionColor="#363636"
                    image="wallet-reward-v2.png"
                    imageWidth="260px"
                    imageHeight="300px"
                    marginTopContent="60px"
                    reverseDirection
                    horizontal
                    padding
                />
                <Card
                    title="Tu dinero crece"
                    description="<p>Una cuenta digital para tu empresa que hace crecer tu dinero, los rendimientos generados, se envían directos a tu cuenta empresarial digital.</p>"
                    descriptionColor="#363636"
                    image="grow.png"
                    imageWidth="260px"
                    imageHeight="270px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
                <Card
                    title="Liquidez inmediata"
                    description="<p>Recibe pagos y paga a través de transferencias interbancarias SPEI, durante el período en que tu dinero no genera rendimientos.</p>"
                    descriptionColor="#363636"
                    image="convenience.png"
                    imageWidth="260px"
                    imageHeight="270px"
                    marginTopContent="60px"
                    reverseDirection
                    horizontal
                    padding
                />
            </PageSection>
            <PageSection maxColumns="3" title="¿Cómo nos diferenciamos?">
                {/*<Table data={dataTable}/>*/}
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="money-secured-icon.png"
                    description="<p><strong>Tu empresa, tu dinero</strong></p><p> Tus depósitos, en una cuenta empresarial tradicional, están en préstamo al banco. En EVVA tu dinero es realmente tuyo.</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="money-grow-icon.png"
                    description="<p><strong>Tu dinero crece</strong></p><p>En cuentas empresariales tradicionales tu dinero pierde valor con el tiempo. En EVVA generas rendimientos y crece.</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="money-difficult-find-icon.png"
                    description="<p><strong>Tu dinero está seguro</strong></p><p>Tu dinero es custodiado por Kuspit Casa de Bolsa, S.A. de C.V. una entidad regulada por la CNBV.</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
            </PageSection>
            <PageSection maxWidth={contentMaxWidth} bgColor="#F3F4F6">
                <Card
                    title="Con EVVA estas protegido"
                    description="<p>Tu cuenta empresarial digital es emitida por Kuspit Casa de Bolsa, institución financiera regulada por BANXICO y CNBV.</p>"
                    descriptionColor="#363636"
                    image="logos-protected-v4.png"
                    imageWidth="260px"
                    imageHeight="265px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
            </PageSection>
            <PageSection title="Preguntas frecuentes" maxWidth={contentMaxWidth} >
                <FAQs items={dataFAQs} filters={dataFilters} categoryFAQsHandler={categoryFAQsHandler} categoryFAQs={appState.categoryFAQs} />
            </PageSection>
            <SecondarySection
                title="Juntos crecemos"
                shortDescription="De emprendedores para emprendedores. Somos gente como tú que entiende tus necesidades. Nuestro objetivo final es que te enfoques en lo que realmente importa: tu empresa."
                position="center"
                image="evva-together.jpg"
                actionLabel="Solicita tu invitación"
                action={launchFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUp"
            />
        </Layout>
    )
};

export default FreelancersAccount
